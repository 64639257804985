import { createRouter, createWebHashHistory } from 'vue-router'


const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import( '../pages/login/login.vue')
  },
  {
    path: '/layout',
    name: 'layout',
    component: () => import( '../pages/layout/layout.vue'),
    children: [
      {
        path: '/workbench',
        name: 'workbench',
        component: () => import( '../pages/workbench/workbench.vue'),
        meta:{
          keepAlive:false,
          title:'工作台'
        }
      },
      {
        path: '/market',
        name: 'market',
        component: () => import( '../pages/market/market.vue'),
        meta:{
          keepAlive:false,
          title:'市场行情看板'
        }
      },
      {
        path: '/stock',
        name: 'stock',
        component: () => import( '../pages/stock/stock.vue'),
        meta:{
          keepAlive:false,
          title:'现货价格分析'
        }
      },
      {
        path: '/operatingDay',
        name: 'operatingDay',
        component: () => import( '../pages/operatingDay/operatingDay.vue'),
        meta:{
          keepAlive:false,
          title:'运行日对比'
        }
      },
      {
        path: '/dataImport',
        name: 'dataImport',
        component: () => import( '../pages/dataImport/dataImport.vue'),
        meta:{
          keepAlive:false,
          title:'数据导入'
        }
      },
      {
        path: '/factor',
        name: 'factor',
        component: () => import( '../pages/factor/factor.vue'),
        meta:{
          keepAlive:false,
          title:'因素模板'
        }
      },

      
      
      
      
    ]
  },
  

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
